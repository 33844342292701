import type { Organization } from '~~/types/organization'
import LoginApiService from '~/services/LoginApiService'
import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const APP_STATUS_STORE_KEY = 'AppStatusStore'

export const useAppStatusStore = defineStore(APP_STATUS_STORE_KEY, () => {

  const defaultOrg: Organization = {
    id: '',
    name: '',
    isAvailableEnglish: false,
    disagreeFlg: '1',
  }
  /**
   *  アプリ情報一覧
   */
  const orgList = ref<Organization[]>([])
  const selectedOrgId = ref<string>('')
  const usrId = ref<string>('')

  const getSelectedOrgId = () => {
    return selectedOrgId.value
  }

  const getUsrId = () => {
    return usrId.value
  }

  const getOrgList = () => {
    return orgList.value
  }

  const getSelectedOrg = () => {
    return orgList.value.find(org => org.id === selectedOrgId.value) ?? defaultOrg
  }

  const resetAppStatus = () => {
    orgList.value.splice(0)
    selectedOrgId.value = ''
    usrId.value = ''
  }

  const setSelectedOrgId = (orgId: string) => {
    selectedOrgId.value = orgId
  }

  const setUsrId = (id: string) => {
    usrId.value = id
  }

  const loadInitAppStatus = async () => {
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadInitAppStatus', true)
    await LoginApiService.getClassrooms().then((resData) => {
      if(resData.code == 0) {
        orgList.value.splice(0)
        resData.f40013DtoList.forEach(element => {
          if (orgList.value.length == 0) {
            setSelectedOrgId(element.orgId)
          }
          const org: Organization = {
            id: element.orgId,
            name: element.orgNm,
            isAvailableEnglish: element.isAvailableEnglish,
            disagreeFlg: element.disagreeFlg,
          }
          orgList.value.push(org)
        })
        if (resData.orgNow != '') {
          setSelectedOrgId(resData.orgNow)
        }
      }
    })
    .catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
    .finally(() => {
      backgroundLoading?.('loadInitAppStatus', false)
    })
  }

  return {
    orgList,
    selectedOrgId,
    usrId,
    getSelectedOrgId,
    getSelectedOrg,
    getUsrId,
    getOrgList,
    resetAppStatus,
    setSelectedOrgId,
    setUsrId,
    loadInitAppStatus,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})

export const notExistManagerStore = () => {
  return !sessionStorage.getItem(APP_STATUS_STORE_KEY)
}
